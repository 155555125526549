import React from "react"
import Layout from "../components/Layout"
import BgImgBanner from "../components/BgImgBanner"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

export default function Home({ data }) {
  if (!data) return null

  const homepageEdge = data.prismic.allHomepages.edges[0]
  if (!homepageEdge) return null
  const content = homepageEdge.node
  const navigationEdges = data.prismic.allNavigations.edges[0]
  if (!navigationEdges) return null
  const navOptions = navigationEdges.node.menu_links
  const activePageMeta = content._meta
  return (
    <Layout activePageMeta={activePageMeta}
            navOptions={navOptions}>
      <SEO lang={activePageMeta.lang}
           title={RichText.asText(content.seo_page_title)}
           description={RichText.asText(content.seo_page_description)}
           social={content.social}/>
      <BgImgBanner className="bg-blue-800 text-white bg-opacity-75"
                   tagLine={content.tag_line}
                   headline={RichText.render(content.headline)}
                   shortDescription={RichText.render(content.short_description)}
                   lowResImage={content.background_image.low_res.url}
                   highResImage={content.background_image.desktop.url}/>
    </Layout>
  )
}

export const query = graphql`
query homepageQuery($lang: String) {
  prismic {
    allHomepages(lang: $lang) {
      edges {
        node {
          tag_line
          headline
          short_description
          background_image
          seo_page_title
          seo_page_description
          _meta {
            id
            lang
            type
            uid
            alternateLanguages {
              lang
              type
            }
          }
          social {
            ... on PRISMIC_HomepageSocialGeneral_card {
              type
              label
              primary {
                title
                image
                description
              }
            }
            ... on PRISMIC_HomepageSocialTwitter_card {
              type
              label
              primary {
                card_type
                description
                image
                title
                twitter_handle
              }
            }
          }
        }
      }
    }
    allNavigations(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`